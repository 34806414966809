<template>
  <div>
    <rol-edit
      :active-modal-edit.sync="activeModalEdit"
      :user-data="userData"
      @activeModalEdit="changeStatusModal"
    />

    <rol-add
      :active-modal-add.sync="activeModalAdd"
      @activeModalAdd="changeStatusModal"
    />
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="activeModalAdd = true"
            >
              Nuevo
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        hover
        fixed
        responsive
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :items="rolData"
        :fields="rolFields"
        :per-page="perPage"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="getData(data.item, data.index,$event.target)"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteRol(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del 1 al 10 de 100 registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import axios from '@axios'
import vSelect from 'vue-select'
import tableData from './rolData'
import RolEdit from './RolEdit.vue'
import RolAdd from './RolAdd.vue'

export default {
  components: {
    vSelect,
    RolEdit,
    RolAdd,
  },
  data() {
    return {
      activeModalEdit: false,
      activeModalAdd: false,

      searchQuery: '',

      sortField: 'id',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      userData: {},

      rolFields: [
        { key: 'id', label: '#' },
        { key: 'rol', label: 'Rol' },
        { key: 'actions', label: 'Acciones' },
      ],
      rolData: tableData.Roles,
    }
  },

  watch: {},
  created() {
    // this.getData()
  },
  methods: {
    getInfo() {
      axios
        .get('rolData.json')
        .then(response => {
          this.rolData = response.data
        })
        .catch(err => {
          this.showErrors(err)
        })
    },
    changeStatusModal(data) {
      this.activeModalEdit = data
      this.activeModalAdd = data
    },
    deleteRol(id) {
      this.$bvModal
        .msgBoxConfirm('¿Deseas eliminar el rol?', {
          title: 'Eliminar rol',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            for (let index = 0; index < this.rolData.length; index += 1) {
              const element = this.rolData[index]
              if (element.id === id) {
                this.rolData.splice(index, 1)
                this.makeToast('success', 'Eliminado', 'Se ha eliminado el rol correctamente')
              }
            }
          }
        })
    },
    getData(data) {
      this.userData = data
      this.activeModalEdit = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
