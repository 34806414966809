<template>
  <div>
    <b-modal
      id="modal-addRol"
      title="AÑADIR ROLES"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-title="Guardar"
      size="lg"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @ok="validacionGuardar"
      @cancel="reset"
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col>
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Rol"
                  rules="required|min: 3"
                >
                  <label>Nombre de rol de usuario</label>
                  <b-form-input
                    v-model="userRol"
                    :state="errors.lenght > 0 ? false:null"
                    class="mt-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div class="mt-1">
        <b-table
          striped
          responsive
          centered
          :items="items"
          :fields="fields"
          class="mb-0"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataRead(data.item, data.index,$event.target)"
            />
          </template>
          <template #cell(write)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataWrite(data.item, data.index,$event.target)"
            />
          </template>
          <template #cell(delete)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataDelete(data.item, data.index,$event.target)"
            />
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import tableData from './rolData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    activeModalAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      userRol: '',
      autorized: 0,
      autorizedWrite: 0,
      items: tableData.Modules,
      itemsRols: tableData.Roles,
      fields: [
        { key: 'name', label: 'Modulo' },
        { key: 'read', label: 'Visualizar' },
        { key: 'write', label: 'Editar' },
        { key: 'delete', label: 'Eliminar' },
      ],
    }
  },
  watch: {
    activeModalAdd: {
      handler() {
        if (this.activeModalAdd === true) {
          this.$root.$emit('bv::show::modal', 'modal-addRol')
          this.$emit('activeModalAdd', false)
        }
      },
    },
  },
  mounted() {},
  methods: {
    validacionGuardar() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.saveData()
          this.userRol = ''
        } else {
          this.makeToast('danger', 'INGRESO INCORRECTO', 'Debes llenar todos los campos requeridos')
          this.$root.$emit('bv::show::modal', 'modal-addRol')
        }
      })
    },
    getDataRead(data) {
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        if (element.id === data.id) {
          if (element.read === true) {
            element.read = false
          } else {
            element.read = true
          }
        }
      }
    },
    getDataWrite(data) {
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        if (element.id === data.id) {
          if (element.write === true) {
            element.write = false
          } else {
            element.write = true
          }
        }
      }
    },
    getDataDelete(data) {
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        if (element.id === data.id) {
          if (element.delete === true) {
            element.delete = false
          } else {
            element.delete = true
          }
        }
      }
    },
    saveData() {
      const authread = this.items.find(result => result.read === true)
      const authwrite = this.items.find(result => result.write === true)
      const authdelete = this.items.find(result => result.delete === true)

      if (authread || authwrite || authdelete) {
        this.itemsRols.push({
          id: this.itemsRols.length + 1,
          rol: this.userRol,
          label: this.userRol,
          modulos: this.items,
        })
        for (let index = 0; index < this.items.length; index += 1) {
          const element = this.items[index]
          element.read = false
          element.write = false
          element.delete = false
        }
        this.makeToast('success', 'Rol éxitoso', 'Se Guardo el rol de usuario con éxito')
      } else {
        this.makeToast('danger', 'INGRESO INCORRECTO', 'Debes asignar al menos un módulo')
        this.$root.$emit('bv::show::modal', 'modal-addRol')
      }
    },
    reset() {
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        element.read = false
        element.write = false
        element.delete = false
      }
      this.userRol = ''
    },
  },
}
</script>
