<template>
  <div>
    <b-modal
      id="modal-editRol"
      title="EDITAR ROLES"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-title="Guardar"
      size="lg"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @ok="validacionGuardar"
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col>
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Rol"
                  rules="required|min: 3"
                >
                  <label>Nombre de rol de usuario</label>
                  <b-form-input
                    v-model="userRol"
                    :state="errors.lenght > 0 ? false:null"
                    class="mt-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div class="mt-1">
        <b-table
          striped
          responsive
          centered
          :items="items"
          :fields="fields"
          class="mb-0"
        >
          <template #cell(read)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataRead(data.item, data.index,$event.target)"
            />
          </template>
          <template #cell(write)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataWrite(data.item, data.index,$event.target)"
            />
          </template>
          <template #cell(delete)="data">
            <b-form-checkbox
              :checked="data.value"
              @change="getDataDelete(data.item, data.index,$event.target)"
            />
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import tableData from './rolData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    activeModalEdit: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      userRol: '',
      itemsRols: tableData.Roles,
      items: [],
      fields: [
        { key: 'name', label: 'Modulo' },
        { key: 'read', label: 'Visualizar' },
        { key: 'write', label: 'Editar' },
        { key: 'delete', label: 'Eliminar' },
      ],

      read: false,
      readData: [],
      write: false,
      writeData: [],
      delete: false,
      deleteData: [],
    }
  },
  watch: {
    activeModalEdit: {
      handler() {
        if (this.activeModalEdit === true) {
          this.$root.$emit('bv::show::modal', 'modal-editRol')
          this.$emit('activeModalEdit', false)
        }
      },
    },
    userData: {
      handler() {
        if (this.userData) {
          this.userRol = this.userData.label
          for (let index = 0; index < this.itemsRols.length; index += 1) {
            const element = this.itemsRols[index]
            if (this.userData.id === element.id) {
              this.items = element.modulos
            }
          }
        }
      },
    },
  },
  mounted() {
    this.userRol = this.userData.label
  },
  methods: {
    validacionGuardar() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.saveData()
        } else {
          this.makeToast('danger', 'INGRESO INCORRECTO', 'Debes llenar todos los campos requeridos')
          this.$root.$emit('bv::show::modal', 'modal-editRol')
        }
      })
    },
    getDataRead(data) {
      if (this.read === false) {
        this.read = true
        this.readData.push({
          id: data.id,
          name: data.name,
          read: this.read,
        })
      } else {
        this.read = false
        for (let index = 0; index < this.readData.length; index += 1) {
          const element = this.readData[index]
          if (element.id === data.id) {
            this.readData.splice(index, 1)
          }
        }
      }
    },
    getDataWrite(data) {
      if (this.write === false) {
        this.write = true
        this.writeData.push({
          id: data.id,
          name: data.name,
          write: this.write,
        })
      } else {
        this.write = false
        for (let index = 0; index < this.writeData.length; index += 1) {
          const element = this.writeData[index]
          if (element.id === data.id) {
            this.writeData.splice(index, 1)
          }
        }
      }
    },
    getDataDelete(data) {
      if (this.delete === false) {
        this.delete = true
        this.deleteData.push({
          id: data.id,
          name: data.name,
          delete: this.delete,
        })
      } else {
        this.delete = false
        for (let index = 0; index < this.deleteData.length; index += 1) {
          const element = this.deleteData[index]
          if (element.id === data.id) {
            this.deleteData.splice(index, 1)
          }
        }
      }
    },
    saveData() {
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        for (let j = 0; j < this.readData.length; j += 1) {
          const readElement = this.readData[j]
          if (element.id === readElement.id) {
            element.read = readElement.read
          }
        }
      }
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        for (let j = 0; j < this.writeData.length; j += 1) {
          const writeElement = this.writeData[j]
          if (element.id === writeElement.id) {
            element.write = writeElement.write
          }
        }
      }
      for (let index = 0; index < this.items.length; index += 1) {
        const element = this.items[index]
        for (let j = 0; j < this.deleteData.length; j += 1) {
          const deleteElement = this.deleteData[j]
          if (element.id === deleteElement.id) {
            element.delete = deleteElement.delete
          }
        }
      }
      for (let index = 0; index < this.itemsRols.length; index += 1) {
        const element = this.itemsRols[index]
        if (element.id === this.userData.id) {
          element.modulos = this.items
        }
      }
      this.userRol = this.userData.label
      this.makeToast('success', 'Rol éxitoso', 'Se modifoco el rol de usuario con éxito')
    },
  },
}
</script>
